.main--gray {
    background: #F9F9FA;
    display: grid;
    grid-template-columns: 20% auto 20%;
    grid-template-rows: 200px auto;
  }
  
  .privacy-header {
    grid-column-start: 2;
    align-self: center;
    justify-self: center;
    text-align: center;
  }
  .privacy-header h1{
    margin-top: 60px;
    color: #4FBA04;
  }
  .privacy-content {
    grid-column-start: 2;
    padding-bottom: 100px;
    text-align: justify;
  }
  
  .privacy-content p {
    font-size: 18px;
    line-height: 1.8em;
    color: #747C83;
    margin-bottom: 25px;
  }
  
  .privacy-content a {
    color: #2f7bbd;
  }
  
  .privacy-content a:hover {
    color: #4c94d3;
  }
  
  .privacy-list li {
    font-size: 18px;
    line-height: 18px;
    color: #8a959e;
    margin: 0 0 10px 70px;
    position: relative;
    list-style: none;
  }
  
  .privacy-list li::before {
    left: -30px;
    position: absolute;
  }
  
  .privacy-list li::before {
    content: "✓";
  }
  .privacy-list2 li {
    font-size: 18px;
    line-height: 18px;
    color: #8a959e;
    margin: 0 0 25px 10px;
    position: relative;
  }
  
  .privacy-list2 li::before {
    left: -30px;
    position: absolute;
  }
  