.page-wrapper {
  min-height: 100%;
  margin-bottom: -50px
}

.page-wrapper:after {
  content: "";
  display: block;
  height: 50px
}
p code{
  word-break: break-word;
  white-space: pre-wrap
}
.footer {
  height: 50px
}

p {
  line-height: 1.5
}

a {
  color: #3aa7aa;
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out
}

a:hover {
  text-decoration: underline;
  color: #339597
}

a:focus {
  text-decoration: none
}
/* tabs */
.tab__container {
  position: relative;
}

.tab__container > ul {
  position: absolute;
  list-style: none;
  margin: 0;
  right: 1rem;
  top: -2rem;
  padding-left: 0;
}

.tab__container .code {
  white-space: normal;
  padding: 1rem 1.5rem;
}

.tab {
  display: inline-block;
  padding: 0.3rem 0.5rem;
  font-weight: 200;
  cursor: pointer;
}

.tab.active {
  border-bottom: 1px solid var(--primary-color);
  font-weight: 700;
  display: inline-block;
}

.tab__pane {
  display: none  !important;
}

.tab__pane.active {
  display: block  !important;
}
pre{
  display: unset  !important;
}
code {
  padding: 2px 8px;
  padding-top: 4px;
  display: inline-block
}

.btn,
a.btn {
  -webkit-transition: all 0.4s ease-in-out;
  -moz-transition: all 0.4s ease-in-out;
  -ms-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  text-decoration: none
}

.btn .svg-inline--fa,
a.btn .svg-inline--fa {
  margin-right: 5px;
  position: relative;
  top: -1px
}

.btn:focus,
a.btn:focus {
  box-shadow: none
}

.btn-primary,
a.btn-primary {
  background: #4FBA04;
  border: 1px solid #4FBA04;
  color: #fff !important
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.btn-primary.hover,
.btn-primary:not(:disabled):not(.disabled):active:focus,
a.btn-primary:hover,
a.btn-primary:focus,
a.btn-primary:active,
a.btn-primary.active,
a.btn-primary.hover,
a.btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #3aa7aa;
  color: #fff !important;
  border: 1px solid #3aa7aa;
  box-shadow: none
}

.btn-green,
a.btn-green {
  background: #75c181;
  border: 1px solid #75c181;
  color: #fff !important
}

.btn-green:hover,
.btn-green:focus,
.btn-green:active,
.btn-green.active,
.btn-green.hover,
a.btn-green:hover,
a.btn-green:focus,
a.btn-green:active,
a.btn-green.active,
a.btn-green.hover {
  background: #63b971;
  color: #fff !important;
  border: 1px solid #63b971
}

.body-green .btn-green,
.body-green a.btn-green {
  color: #fff !important
}

.body-green .btn-green:hover,
.body-green .btn-green:focus,
.body-green .btn-green:active,
.body-green .btn-green.active,
.body-green .btn-green.hover,
.body-green a.btn-green:hover,
.body-green a.btn-green:focus,
.body-green a.btn-green:active,
.body-green a.btn-green.active,
.body-green a.btn-green.hover {
  color: #fff !important
}

.btn-blue,
a.btn-blue {
  background: #58bbee;
  border: 1px solid #58bbee;
  color: #fff !important
}

.btn-blue:hover,
.btn-blue:focus,
.btn-blue:active,
.btn-blue.active,
.btn-blue.hover,
a.btn-blue:hover,
a.btn-blue:focus,
a.btn-blue:active,
a.btn-blue.active,
a.btn-blue.hover {
  background: #41b2ec;
  color: #fff !important;
  border: 1px solid #41b2ec
}

.btn-orange,
a.btn-orange {
  background: #F88C30;
  border: 1px solid #F88C30;
  color: #fff !important
}

.btn-orange:hover,
.btn-orange:focus,
.btn-orange:active,
.btn-orange.active,
.btn-orange.hover,
a.btn-orange:hover,
a.btn-orange:focus,
a.btn-orange:active,
a.btn-orange.active,
a.btn-orange.hover {
  background: #f77e17;
  color: #fff !important;
  border: 1px solid #f77e17
}

.btn-red,
a.btn-red {
  background: #f77b6b;
  border: 1px solid #f77b6b;
  color: #fff !important
}

.btn-red:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active,
.btn-red.hover,
a.btn-red:hover,
a.btn-red:focus,
a.btn-red:active,
a.btn-red.active,
a.btn-red.hover {
  background: #f66553;
  color: #fff !important;
  border: 1px solid #f66553
}

.btn-pink,
a.btn-pink {
  background: #EA5395;
  border: 1px solid #EA5395;
  color: #fff !important
}

.btn-pink:hover,
.btn-pink:focus,
.btn-pink:active,
.btn-pink.active,
.btn-pink.hover,
a.btn-pink:hover,
a.btn-pink:focus,
a.btn-pink:active,
a.btn-pink.active,
a.btn-pink.hover {
  background: #e73c87;
  color: #fff !important;
  border: 1px solid #e73c87
}

.btn-purple,
a.btn-purple {
  background: #8A40A7;
  border: 1px solid #8A40A7;
  color: #fff !important
}

.btn-purple:hover,
.btn-purple:focus,
.btn-purple:active,
.btn-purple.active,
.btn-purple.hover,
a.btn-purple:hover,
a.btn-purple:focus,
a.btn-purple:active,
a.btn-purple.active,
a.btn-purple.hover {
  background: #7b3995;
  color: #fff !important;
  border: 1px solid #7b3995
}

.btn-cta {
  padding: 7px 15px
}

.search-btn {
  height: 40px
}

.search-btn .svg-inline--fa {
  top: 0;
  margin-right: 0
}

.form-control {
  box-shadow: none;
  height: 40px;
  border-color: #f0f0f0
}

.form-control::-webkit-input-placeholder {
  color: #afb3bb
}

.form-control:-moz-placeholder {
  color: #afb3bb
}

.form-control::-moz-placeholder {
  color: #afb3bb
}

.form-control:-ms-input-placeholder {
  color: #afb3bb
}

.form-control:focus {
  border-color: #e3e3e3;
  box-shadow: none
}

input[type="text"],
input[type="email"],
input[type="password"],
input[type="submit"],
input[type="button"],
textarea,
select {
  -moz-appearance: none;
  appearance: none;
  -webkit-appearance: none
}

.header {
  background: #282C47;
  color: rgba(255, 255, 255, 0.85);
  border-top: 5px solid #4FBA04;
  padding: 30px 0
}

.header a {
  color: #fff
}

.header .container {
  position: relative
}

.branding {
  text-transform: uppercase;
  margin-bottom: 10px
}

.branding .logo {
  font-size: 28px;
  margin-top: 0;
  margin-bottom: 0
}

.branding .logo a {
  text-decoration: none
}

.branding .text-highlight {
  color: #4FBA04
}

.body-green .branding .text-highlight {
  color: #75c181
}

.body-blue .branding .text-highlight {
  color: #58bbee
}

.body-orange .branding .text-highlight {
  color: #F88C30
}

.body-red .branding .text-highlight {
  color: #f77b6b
}

.body-pink .branding .text-highlight {
  color: #EA5395
}

.body-purple .branding .text-highlight {
  color: #8A40A7
}

.branding .text-bold {
  font-weight: 800;
  color: #fff
}

.branding .icon {
  font-size: 24px;
  color: #4FBA04
}

.body-green .branding .icon {
  color: #75c181
}

.body-blue .branding .icon {
  color: #58bbee
}

.body-orange .branding .icon {
  color: #F88C30
}

.body-red .branding .icon {
  color: #f77b6b
}

.body-pink .branding .icon {
  color: #EA5395
}

.body-purple .branding .icon {
  color: #8A40A7
}

.breadcrumb {
  background: none;
  margin-bottom: 0;
  padding: 0
}

.breadcrumb li {
  color: rgba(255, 255, 255, 0.5)
}

.breadcrumb li.active {
  color: rgba(255, 255, 255, 0.5)
}

.breadcrumb li a {
  color: rgba(255, 255, 255, 0.5)
}

.breadcrumb li a:hover {
  color: #fff
}

.breadcrumb>li+li:before {
  color: rgba(0, 0, 0, 0.4)
}

.search-form {
  position: relative
}

.search-form .search-input {
  font-size: 14px;
  border-radius: 20px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  padding-top: 4px
}

.search-form .search-input:focus {
  border-color: #616670
}

.search-form .search-btn {
  color: #797f8b;
  background: none;
  border: none;
  position: absolute;
  right: 5px;
  top: 0px;
  margin-right: 0
}

.search-form .search-btn:active,
.search-form .search-btn:focus,
.search-form .search-btn:hover {
  outline: none !important;
  color: #31343a
}

.top-search-box {
  position: absolute;
  right: 15px;
  top: 15px
}

.footer {
  background: #26282c;
  color: rgba(255, 255, 255, 0.6);
  padding: 15px 0
}

.footer a {
  color: #4FBA04
}

.footer .fa-heart {
  color: #EA5395
}

@media (max-width: 575.98px) {
  .top-search-box {
    width: 100%;
    position: static;
    margin-top: 15px
  }
}

.doc-wrapper {
  padding: 45px 0;
  background: #f9f9fb
}

.doc-body {
  position: relative
}

.doc-header {
  margin-bottom: 30px;
  text-align: right;
}

.doc-header .doc-title {
  color: #4FBA04;
  margin-top: 0;
  font-size: 36px
}

.body-green .doc-header .doc-title {
  color: #75c181
}

.body-blue .doc-header .doc-title {
  color: #58bbee
}

.body-orange .doc-header .doc-title {
  color: #F88C30
}

.body-red .doc-header .doc-title {
  color: #f77b6b
}

.body-pink .doc-header .doc-title {
  color: #EA5395
}

.body-purple .doc-header .doc-title {
  color: #8A40A7
}

.doc-header .icon {
  font-size: 30px
}

.doc-header .meta {
  color: #a2a6af
}

.doc-content {
  position: relative;
  z-index: 10
}

.doc-section {
  padding-top: 15px;
  padding-bottom: 15px
}

.doc-section .section-title {
  font-size: 26px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #d7d7d7
}

.doc-section h1 {
  font-size: 24px;
  font-weight: bold
}

.doc-section h2 {
  font-size: 22px;
  font-weight: bold
}

.doc-section h3 {
  font-size: 20px;
  font-weight: bold
}

.doc-section h4 {
  font-size: 18px;
  font-weight: bold
}

.doc-section h5 {
  font-size: 16px;
  font-weight: bold
}

.doc-section h6 {
  font-size: 14px;
  font-weight: bold
}

.section-block {
  padding-top: 15px;
  padding-bottom: 15px
}

.section-block .block-title {
  margin-top: 0
}

.section-block .list>li {
  margin-bottom: 10px
}

.section-block .list ul>li {
  margin-top: 5px
}

.question {
  font-weight: 400 !important;
  color: #3aa7aa
}

.question .body-green {
  color: #63b971
}

.body-blue .question {
  color: #41b2ec
}

.body-orange .question {
  color: #f77e17
}

.body-pink .question {
  color: #e73c87
}

.body-purple .question {
  color: #7b3995
}

.question .svg-inline--fa {
  -webkit-opacity: .6;
  -moz-opacity: .6;
  opacity: .6;
  position: relative;
  top: -2px
}

.question .badge {
  font-size: 11px;
  vertical-align: middle
}

.answer {
  color: #616670
}

.code-block {
  margin-top: 30px;
  margin-bottom: 30px
}

.callout-block {
  padding: 30px;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  margin-bottom: 30px
}

.callout-block a {
  color: rgba(0, 0, 0, 0.55) !important
}

.callout-block a:hover {
  color: rgba(0, 0, 0, 0.65) !important
}

.callout-block .icon-holder {
  font-size: 30px;
  position: absolute;
  left: 30px;
  top: 30px;
  color: rgba(0, 0, 0, 0.25)
}

.callout-block .content {
  margin-left: 60px
}

.callout-block .content p:last-child {
  margin-bottom: 0
}

.callout-block .callout-title {
  margin-top: 0;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.65)
}

.callout-info {
  background: #58bbee;
  color: #fff
}

.callout-success {
  background: #75c181;
  color: #fff
}

.callout-warning {
  background: #F88C30;
  color: #fff
}

.callout-danger {
  background: #f77b6b;
  color: #fff
}

.table>thead>tr>th {
  border-bottom-color: #8bd6d8
}

.body-green .table>thead>tr>th {
  border-bottom-color: #bbe1c1
}

.body-blue .table>thead>tr>th {
  border-bottom-color: #b5e1f7
}

.body-orange .table>thead>tr>th {
  border-bottom-color: #fbc393
}

.body-pink .table>thead>tr>th {
  border-bottom-color: #f5aecd
}

.body-purple .table>thead>tr>th {
  border-bottom-color: #b87fce
}

.table-bordered>thead>tr>th {
  border-bottom-color: inherit
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #f5f5f5
}

.screenshot-holder {
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  text-align: center
}

.screenshot-holder img {
  border: 1px solid #f0f0f0
}

.screenshot-holder .mask {
  display: block;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  text-decoration: none
}

.screenshot-holder .mask .svg-inline--fa {
  color: #fff;
  font-size: 42px;
  display: block;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px
}

.screenshot-holder:hover .mask {
  visibility: visible
}

.jumbotron h1 {
  font-size: 28px;
  margin-top: 0;
  margin-bottom: 30px
}

.author-profile {
  margin-top: 30px
}

.author-profile img {
  width: 100px;
  height: 100px
}

.speech-bubble {
  background: #fff;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  padding: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
  position: relative
}

.speech-bubble .speech-title {
  font-size: 16px
}

.jumbotron .speech-bubble p {
  font-size: 14px;
  font-weight: normal;
  color: #616670
}

.speech-bubble:before {
  content: "";
  display: inline-block;
  position: absolute;
  left: 50%;
  top: -10px;
  margin-left: -10px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff
}

.theme-card {
  text-align: center;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  position: relative;
  height: 100%
}

.theme-card .card-block {
  padding: 15px
}

.theme-card .mask {
  display: block;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.25);
  cursor: pointer;
  text-decoration: none
}

.theme-card .mask .icon {
  color: #fff;
  font-size: 42px;
  margin-top: 25%
}

.theme-card:hover .mask {
  visibility: visible
}

.body-green .header {
  border-color: #75c181
}

.body-green a {
  color: #75c181
}

.body-green a:hover {
  color: #52b161
}

.body-blue .header {
  border-color: #58bbee
}

.body-blue a {
  color: #58bbee
}

.body-blue a:hover {
  color: #2aa8e9
}

.body-orange .header {
  border-color: #F88C30
}

.body-orange a {
  color: #F88C30
}

.body-orange a:hover {
  color: #ed7108
}

.body-pink .header {
  border-color: #EA5395
}

.body-pink a {
  color: #EA5395
}

.body-pink a:hover {
  color: #e42679
}

.body-purple .header {
  border-color: #8A40A7
}

.body-purple a {
  color: #8A40A7
}

.body-purple a:hover {
  color: #6c3282
}

.body-red .header {
  border-color: #f77b6b
}

.body-red a {
  color: #f77b6b
}

.body-red a:hover {
  color: #f4503b
}
.doc-sidebar{
  width: 100%;
  height: 85vh; 
  min-height: 200px;
  overflow: auto;
  position: -webkit-sticky;
  position: sticky;
  top: 5%;
}

.doc-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
}

.sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  top: 0
}

.doc-menu {
  list-style: none
}

.doc-menu .nav-link {
  margin-bottom: 5px;
  display: block;
  padding: 5px 15px;
  color: #616670
}
.doc-menu .nav-link.selected {
  background: none;
  color: #4FBA04;
  font-weight: 600
}


.doc-menu .nav-link:hover,
.doc-menu .nav-link:focus {
  color: #494d55;
  text-decoration: none;
  background: none
}

.doc-menu .active .nav-link {
  background: none;
  color: #4FBA04;
  font-weight: 600
}

.nav-item.active .nav-link {
  background: none;
  color: #4FBA04;
  font-weight: 600
}

.body-green .nav-item.active .nav-link {
  color: #75c181;
  border-color: #75c181
}

.body-blue .nav-item.active .nav-link {
  color: #58bbee;
  border-color: #58bbee
}

.body-orange .nav-item.active .nav-link {
  color: #F88C30;
  border-color: #F88C30
}

.body-red .nav-item.active .nav-link {
  color: #f77b6b;
  border-color: #f77b6b
}

.body-pink .nav-item.active .nav-link {
  color: #EA5395;
  border-color: #EA5395
}

.body-purple .nav-item.active .nav-link {
  color: #8A40A7;
  border-color: #8A40A7
}

.doc-sub-menu {
  list-style: none;
  padding-left: 0
}

.doc-sub-menu .nav-link {
  margin-bottom: 10px;
  font-size: 12px;
  display: block;
  color: #616670;
  padding: 0;
  padding-left: 34px;
  background: none
}

.doc-sub-menu .nav-link:first-child {
  padding-top: 5px
}

.doc-sub-menu .nav-link:hover {
  color: #494d55;
  text-decoration: none;
  background: none
}

.doc-sub-menu .nav-link:focus {
  background: none
}


.doc-sub-menu-s {
  list-style: none;
  padding-left: 0
}

.doc-sub-menu-s .nav-link {
  margin-bottom: 10px;
  font-size: 12px;
  display: block;
  color: #616670;
  padding: 0;
  padding-left: 54px;
  background: none
}

.doc-sub-menu-s .nav-link:first-child {
  padding-top: 5px
}

.doc-sub-menu-s .nav-link:hover {
  color: #494d55;
  text-decoration: none;
  background: none
}

.doc-sub-menu-s .nav-link:focus {
  background: none
}

.promo-block {
  background: #3aa7aa
}

.body-green .promo-block {
  background: #63b971
}

.body-blue .promo-block {
  background: #41b2ec
}

.body-orange .promo-block {
  background: #f77e17
}

.body-pink .promo-block {
  background: #e73c87
}

.body-purple .promo-block {
  background: #7b3995
}

.promo-block a {
  color: rgba(0, 0, 0, 0.6);
  font-weight: bold
}

.promo-block a:hover {
  color: rgba(0, 0, 0, 0.7)
}

.promo-block .promo-block-inner {
  padding: 45px;
  color: #fff;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box
}

.promo-block .promo-title {
  font-size: 20px;
  font-weight: 800;
  margin-top: 0;
  margin-bottom: 45px
}

.promo-block .promo-title .svg-inline--fa {
  color: rgba(0, 0, 0, 0.6)
}

.promo-block .figure-holder-inner {
  background: #fff;
  margin-bottom: 30px;
  position: relative;
  text-align: center
}

.promo-block .figure-holder-inner img {
  border: 5px solid #fff
}

.promo-block .figure-holder-inner .mask {
  display: block;
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  cursor: pointer;
  text-decoration: none
}

.promo-block .figure-holder-inner .mask .svg-inline--fa {
  color: #fff;
  font-size: 36px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -18px;
  margin-top: -18px
}

.promo-block .figure-holder-inner .mask .svg-inline--fa.pink {
  color: #EA5395
}

.promo-block .figure-holder-inner:hover .mask {
  visibility: visible
}

.promo-block .content-holder-inner {
  padding-left: 15px;
  padding-right: 15px
}

.promo-block .content-title {
  font-size: 16px;
  font-weight: 600;
  margin-top: 0
}

.promo-block .highlight {
  color: rgba(0, 0, 0, 0.6)
}

.promo-block .btn-cta {
  background: rgba(0, 0, 0, 0.35);
  border: none;
  color: #fff !important;
  margin-bottom: 15px
}

.promo-block .btn-cta:hover {
  background: rgba(0, 0, 0, 0.5);
  border: none;
  color: #fff !important
}

@media (max-width: 767px) {
  .jumbotron {
    padding: 30px 15px
  }

  .jumbotron h1 {
    font-size: 24px;
    margin-bottom: 15px
  }

  .jumbotron p {
    font-size: 18px
  }

  .promo-block .promo-block-inner {
    padding: 30px 15px
  }

  .promo-block .content-holder-inner {
    padding: 0
  }

  .promo-block .promo-title {
    margin-bottom: 30px
  }
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0
}

.sticky:before,
.sticky:after {
  content: '';
  display: table
}

.landing-page .header {
  background: #494d55;
  color: rgba(255, 255, 255, 0.85);
  padding: 60px 0
}

.landing-page .header a {
  color: #fff
}

.landing-page .branding {
  text-transform: uppercase;
  margin-bottom: 20px
}

.landing-page .branding .logo {
  font-size: 38px;
  margin-top: 0;
  margin-bottom: 0
}

.landing-page .branding .text-bold {
  font-weight: 800;
  color: #fff
}

.landing-page .branding .icon {
  font-size: 32px;
  color: #4FBA04
}

.landing-page .tagline {
  font-weight: 600;
  font-size: 20px
}

.landing-page .tagline p {
  margin-bottom: 5px
}

.landing-page .tagline p:last-child {
  margin-bottom: 0
}

.landing-page .tagline .text-highlight {
  color: #266f71
}

.landing-page .fa-heart {
  color: #EA5395
}

.landing-page .cta-container {
  margin-top: 30px
}

.landing-page .social-container .twitter-tweet {
  display: inline-block;
  margin-right: 5px;
  position: relative
}

.landing-page .social-container .fb_iframe_widget {
  display: inline-block;
  position: relative;
  top: 3px
}

.cards-section {
  padding: 60px 0;
  background: #f9f9fb
}

.cards-section .title {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 24px;
  font-weight: 600
}

.cards-section .intro {
  margin: 0 auto;
  max-width: 800px;
  margin-bottom: 60px;
  color: #616670
}

.cards-section .cards-wrapper {
  max-width: 860px;
  margin-left: auto;
  margin-right: auto
}

.cards-section .item {
  margin-bottom: 30px
}

.cards-section .item .icon-holder {
  margin-bottom: 15px
}

.cards-section .item .icon {
  font-size: 36px
}

.cards-section .item .title {
  font-size: 16px;
  font-weight: 600
}

.cards-section .item .intro {
  margin-bottom: 15px
}

.cards-section .item-inner {
  padding: 45px 30px;
  background: #fff;
  position: relative;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  height: 100%
}

.cards-section .item-inner .link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1
}

.cards-section .item-inner:hover {
  background: #f5f5f5
}

.cards-section .item-primary .item-inner {
  border-top: 3px solid #4FBA04
}

.cards-section .item-primary .item-inner:hover .title {
  color: #2d8284
}

.cards-section .item-primary .icon {
  color: #4FBA04
}

.cards-section .item-green .item-inner {
  border-top: 3px solid #75c181
}

.cards-section .item-green .item-inner:hover .title {
  color: #48a156
}

.cards-section .item-green .icon {
  color: #75c181
}

.cards-section .item-blue .item-inner {
  border-top: 3px solid #58bbee
}

.cards-section .item-blue .item-inner:hover .title {
  color: #179de2
}

.cards-section .item-blue .icon {
  color: #58bbee
}

.cards-section .item-orange .item-inner {
  border-top: 3px solid #F88C30
}

.cards-section .item-orange .item-inner:hover .title {
  color: #d46607
}

.cards-section .item-orange .icon {
  color: #F88C30
}

.cards-section .item-red .item-inner {
  border-top: 3px solid #f77b6b
}

.cards-section .item-red .item-inner:hover .title {
  color: #f33a22
}

.cards-section .item-red .icon {
  color: #f77b6b
}

.cards-section .item-pink .item-inner {
  border-top: 3px solid #EA5395
}

.cards-section .item-pink .item-inner:hover .title {
  color: #d61a6c
}

.cards-section .item-pink .icon {
  color: #EA5395
}

.cards-section .item-purple .item-inner {
  border-top: 3px solid #8A40A7
}

.cards-section .item-purple .item-inner:hover .title {
  color: #5c2b70
}

.cards-section .item-purple .icon {
  color: #8A40A7
}

@media (max-width: 575.98px) {
  .main-search-box {
    width: 100%
  }

  .main-search-box .search-form .search-input {
    width: 100%
  }
}

@media (max-width: 767.98px) {
  .cards-section .item-inner {
    padding: 30px 15px
  }
}

@media (min-width: 576px) {
  .main-search-box .search-form .search-input {
    width: 400px
  }
}

@media (min-width: 768px) {
  .main-search-box .search-form .search-input {
    width: 560px
  }
}
.icon {
  width: 5%;
  height: 5%;
  grid-column: 2;
  top: 0;
  bottom: 0;
  margin: auto;
}