* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

html,
body,
.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6 {
font-family: 'OpenSans', "Helvetica Neue", Helvetica, Arial, "Microsoft Yahei", "微软雅黑", STXihei, "华文细黑", sans-serif;
font-size: 14px;
}
img {
max-width: 80%;
height: auto;
}
