.btn {
    border-radius: 10px;
    background: #4FBA04;
    white-space: nowrap;
    padding: 14px;
    color: #010606;
    font-size: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin-bottom: 1%;
    position: block;
   margin-left: auto;
   margin-right: auto;

}

.btn-hiring {
    background: #fff;
    white-space: nowrap;
    padding: 14px;
    color: #2C2C44;
    font-size: 20px;
    outline: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-bottom: 1%;
    width: 100%;
    border-radius: 15px;
}

.btn:hover {
    transition: all 0.2s ease-in-out;
    background: #fff;
    color: #4FBA04;
}

#popup {
    top: -100%;
    left: 0%;
    z-index: 1000;
    background: #fff;
    width: 450px;
    padding: 10px 10px 10px;
    box-shadow: 15px 30px rgba(0, 0, 0, 0.08);
    
}


#popup .content {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#popup .content img {
    max-width: 180px;
}

#popup .content h2 {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    margin: 20px 0 10px;
    text-align: center;
}

#popup .content p {
    text-align: center;
    color: #333;
    font-size: 16px;
    margin-right: auto;
}

.modal>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: -10px;
    top: -10px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.modal2 {
    margin-top: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.modal2>.close {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    top: 60px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}
.modal2>.close2 {
    cursor: pointer;
    position: absolute;
    display: block;
    padding: 2px 5px;
    line-height: 20px;
    right: 50px;
    top: 20px;
    font-size: 24px;
    background: #ffffff;
    border-radius: 18px;
    border: 1px solid #cfcece;
}

.modal3 {
    margin-top: 5%;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.modal3>.close3 {
    cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: 10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}